<script>
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import store from "../../state/store";
import { mapGetters } from "vuex";
import ImmigrationRequestServices from "@/services/ImmigrationRequestServices";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Liste des requêtes d'immigration",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Liste des requêtes d'immigration",
      items: [
        {
          text: "Requêtes d'immigration",
          href: "/",
        },
        {
          text: "Liste des requêtes d'immigration",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    MoreHorizontalIcon,
  },
  async beforeRouteEnter() {
    await store.dispatch("immigrationRequests/findAll");
  },
  methods: {
    async getSubscriptions() {
      await store.dispatch("immigrationRequests/findAll");
    },
    async handleCloseRequest(item) {
      let resend = false;

      await Swal.fire({
        title: "Voulez-vous vraiment archiver cette requête ?",
        text: "Cette action est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, archiver",
        cancelButtonText: "Non, annuler",
        confirmButtonClass: "btn btn-primary w-xs mt-2 mr-2",
        cancelButtonClass: "btn btn-light w-xs mt-2 ml-2",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          resend = true;
        }
      });

      if (!resend) {
        return;
      }

      await ImmigrationRequestServices.delete(item.id)
        .then(() => {
          this.$saSuccess("Message", "La requête a été archivée avec succès.");
        })
        .catch((e) => {
          this.$saError("Erreur", "Erreur lors l'archivage de la requête.");
          console.log(e);
        })
        .finally(() => {
          store.dispatch("immigrationRequests/findAll");
        });
    },
  },
  computed: {
    ...mapGetters("immigrationRequests", ["requests"]),
    subscriptions() {
      return this.requests;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row g-4 mb-3">
      <div class="col-sm-auto">
        <!-- <div>
          <router-link to="/apps/projects-create" class="btn btn-success"
            ><i class="ri-add-line align-bottom me-1"></i> Add New</router-link
          >
        </div> -->
      </div>
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
          <div class="search-box ms-2">
            <input
              type="text"
              class="form-control"
              placeholder="Recherche..."
            />
            <i class="ri-search-line search-icon"></i>
          </div>

          <!-- <Multiselect
            class="multiselect form-control w-lg w-auto m-0"
            v-model="value"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'Status', label: 'Status' },
              { value: 'Active', label: 'Active' },
              { value: 'Block', label: 'Block' },
            ]"
          /> -->
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-xxl-3 col-sm-6 project-card"
        v-for="(item, index) of subscriptions"
        :key="index"
      >
        <div class="card">
          <div class="card-body">
            <div class="p-3 mt-n3 mx-n3 bg-soft-info rounded-top">
              <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                  <h5 class="mb-0 fs-14">
                    <router-link
                      :to="{
                        name: 'immigration-requests.details',
                        params: { id: item.id },
                      }"
                      class="text-dark"
                    >
                      {{ item.code }}
                    </router-link>
                  </h5>
                </div>
                <div class="flex-shrink-0">
                  <div class="d-flex gap-1 align-items-center my-n2">
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <MoreHorizontalIcon
                          class="icon-sm"
                        ></MoreHorizontalIcon>
                      </button>

                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'immigration-requests.details',
                            params: { id: item.id },
                          }"
                        >
                          <i
                            class="ri-eye-fill align-bottom me-2 text-muted"
                          ></i>
                          Afficher les détails
                        </router-link>
                        <!-- <router-link
                          class="dropdown-item"
                          to="/apps/projects-create"
                          ><i
                            class="ri-pencil-fill align-bottom me-2 text-muted"
                          ></i>
                          Edit</router-link
                        >
                      -->
                        <div class="dropdown-divider"></div>
                        <a
                          class="dropdown-item text-danger"
                          href="javascript:void(0)"
                          @click="handleCloseRequest(item)"
                        >
                          <i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          Archiver la requête
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-3">
              <div class="mb-2">
                <p class="mb-1">
                  {{ item?.last_name }} {{ item?.first_name }} ({{
                    item?.phone_number
                  }})
                </p>
              </div>

              <div class="row gy-3">
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Niveau</p>
                    <div :class="`badge badge-soft-info fs-12`">
                      {{ item.level }} / {{ item.steps.length }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div>
                    <p class="text-muted mb-1">Pays</p>
                    <h5 class="badge badge-soft-success fs-12">
                      {{ "Chine" }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex mb-2">
                <div class="flex-grow-1">
                  <div>Progression</div>
                </div>
                <div class="flex-shrink-0">
                  <div>
                    {{ ((item.level / item.steps.length) * 100).toFixed(2) }}%
                  </div>
                </div>
              </div>
              <div class="progress progress-sm animated-progess">
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  :style="`width: ${(
                    (item.level / item.steps.length) *
                    100
                  ).toFixed(2)}%`"
                ></div>
                <!-- /.progress-bar -->
              </div>
              <!-- /.progress -->
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
